import styled from 'styled-components'
import media from 'styled-media-query'
import { CircleButton } from '../../shared/CircleButton/styled';
import { alvTokens } from '@dasa-health/alma-tokens'

export const ClinicalStaffBackground = styled.div`
  width: 100%;
  background-color: ${alvTokens.ColorPaletteGray160};
`;

export const ClinicalStaffContainer = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  padding: 17rem 0 8rem;
  
  ${media.lessThan('medium')`
    padding: 4rem 1.6rem;
  `}
`;

export const ClinicalStaffContent = styled.div`
  display: flex;
  max-width: 1095px;
  margin: 0 auto;

  ${media.lessThan('medium')`
    flex-direction: column;
    max-width: 100vw;
    padding: 0;
  `}
`

export const ClinicalStaffColumnInfo = styled.div`
`

export const ClinicalStaffImgDiv = styled.div`
  display: flex;
  width: 4.8rem;
  height: 100%;
  margin-right: 1.6rem;

  .gatsby-image-wrapper {
    width: 4.8rem;
    height: 4.8rem;
    margin: auto;
    border-radius: 500px;
  }
`

export const ClinicalStaffText = styled.div`
  width: 424px;
  margin-right: 2.4rem;

  ${media.lessThan('medium')`
    width: calc(100vw - 3.2rem);
  `}
`

export const ClinicalStaffTitle = styled.h2`
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -1px;
  color: ${props => props.theme.colors.brand.primary[10]};
  margin: 0 0 2.4rem 0;
  
  ${media.lessThan('medium')`
    max-width: 25rem;
    font-size: 24px;
  `}
`;

export const ClinicalStaffDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${alvTokens.ColorNeutralInverse20};
  margin: 2.4rem 0;
  max-width: 373.55px;

  ${media.lessThan('medium')`
    max-width: 100%;
  `}
`;

export const BtnsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.6rem;

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const BtnSwiperPrev = styled.button`
    width: 3.2rem;
    height: 3.2rem;
    border: 0;
    padding: 0;
    color: ${props => props.theme.colors.palette.black};
    background-color: ${alvTokens.ColorPaletteGray160};
    cursor: pointer;

    &:disabled {
      color: #C8C8C8;
    }

    svg {
      font-size: 3.2rem;
    }
`
export const BtnSwiperNext = styled.button`
    width: 3.2rem;
    height: 3.2rem;
    border: 0;
    padding: 0;
    margin-left: 2.3rem;
    color: ${props => props.theme.colors.palette.black};
    background-color: ${alvTokens.ColorPaletteGray160};
    cursor: pointer;

    &:disabled {
      color: #C8C8C8;
    }

    svg {
      font-size: 3.2rem;
    }
`

export const ClinicalStaffCarousel = styled.div`
  position: relative;
  max-width: 655px;
  min-width: 655px;

  ${media.lessThan('medium')`
    max-width: 100%;
    min-width: 0;
  `}

  .swiper-container {
    min-height: 408px;

    ${media.lessThan('medium')`
      min-height: 406px;
    `}

    .swiper-wrapper {
      gap: 0;
      
      ${media.lessThan('medium')`
        gap: 16px;
      `}

      .swiper-filler {
        width: calc(100vw - 300px);
        display: block;
      }
    }

    .swiper-slide {
      display: grid;
      width: 312px !important;
      gap: 32px 0;

      ${media.greaterThan('medium')`
        &:nth-child(odd) {
          margin-right: 2.4rem !important;
        }

        &:nth-child(2n+3) {
          margin-left: 5px !important;
        }

        &.swiper-filler {
          display: none;
        }
      `}

      ${media.lessThan('medium')`
        gap: 16px;
        width: 237px !important;
        margin: 0 !important;

        &.swiper-filler {
          width: calc(100vw - 300px) !important;
          order: 999 !important;
        }
      `}
    }
  }

  .swiper-pagination {
    width: 80%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    align-content: center;
    padding: 1.2rem 0;

    &.swiper-pagination-bullets {
        bottom: .4rem;

        ${media.lessThan('medium')`
          bottom: 0;
          height: .8rem;
        `}
    }

    &.swiper-pagination-bullets,
    &.swiper-pagination-clickable {
      .swiper-pagination-bullet {
        display: block;
        width: .8rem;
        height: .8rem;
        margin: 0 5px;
        border-radius: 10px;
        opacity: 1;
        background: #D12673;
        transition: all 0.3s;
      }

      .swiper-pagination-bullet-active {
        width: 2.4rem;
        height: .8rem;
        opacity: 1;
        background: #0C42F9;
        box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3);

        ${media.lessThan('medium')`
          background: ${props => props.theme.colors.brand.primary[10]};
        `}
      }
    }
  }
`

export const ClinicalStaffItem = styled.div`
  display: flex;
  align-items: center;
  width: 27.8rem;
  height: 7.2rem;
  padding: 1.6rem;
  border: 1px solid rgba(22, 22, 22, 0.08);
  border-radius: 8px;
  background-color: ${alvTokens.ColorPaletteWhite};

  ${media.lessThan('medium')`
    width: 20.3rem;
  `}
`

export const ItemColumnLeft = styled.div`
  min-width: 176px;
  height: 100%;
  margin-right: 1.6rem;

  ${media.lessThan('medium')`
    min-width: 10.1rem;
    width: 10.1rem;
  `}
`

export const ClinicalStaffName = styled.h3`
  display: flex;
  width: 16rem;
  min-height: 4.8rem;
  margin: 0 0 .2rem;

  ${media.lessThan('medium')`
    min-width: 0;
    max-width: 10.1rem;
  `}

  a {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: ${props => props.theme.colors.palette.black};
    margin: 0;
    text-decoration: none;

    ${media.lessThan('medium')`
      font-size: 14px;
    `}
  }
`

export const ClinicalStaffSpecialization = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: ${props => props.theme.colors.palette.black};
  margin: 0;

  ${media.lessThan('medium')`
    font-size: 12px;
  `}
`

export const Link = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;

  a {
    color: ${props => props.theme.colors.palette.black};
  }

  svg {
    font-size: 1.24rem;
  }
`

export const ItemColumnRight = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`

export const CircleButtonBlue = styled(CircleButton)`
  ${media.lessThan('medium')`
    width: 3.2rem;
    height: 3.2rem;

    &.hiddenDesktop {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    svg {
        font-size: 24px;
    }
  `}
`

export const ButtonMobile = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;

  ${media.greaterThan('medium')`
    display: none;
  `}
`