import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import getSlugByCountry from '../../utils/getSlugByCountry'

const Pagination = ({ currentPage, totalPages, setPage, slug }) => {
  const isNotAbleToPrev = currentPage === 0
  const isNotAbleToNext = currentPage === totalPages - 1

  const backLink = () => {
    switch (currentPage - 1) {
      case 0:
        return '/'
      case 1:
        return `/${slug}/`
      default:
        return `/${slug}/${currentPage - 1}/`
    }
  }

  const clinicalStaffPageSlug = getSlugByCountry('corpoClinico')
  const frontLink = () => (currentPage + 1 > totalPages ? '/' : `/${clinicalStaffPageSlug}/pagina/${currentPage + 1}`)

  return (
    <S.PaginationList>
      <S.PaginationListItem>
        <S.PaginationListItemLink to={backLink()} disabled={isNotAbleToPrev}>
          <S.PaginationIconLeft 
            onClick={() => {
              setPage(currentPage - 1)
            }} />
        </S.PaginationListItemLink>
      </S.PaginationListItem>
      <S.PaginationListItem>
        {((currentPage + 1) < totalPages && currentPage !== 0) && (
          <S.PaginationItem
            onClick={() => {
              setPage(currentPage - 1)
            }}
          >
            {currentPage}
          </S.PaginationItem>
        )}

        {(currentPage + 1) === totalPages && currentPage !== 0 && (
          <S.PaginationItem
            onClick={() => {
              setPage(currentPage - 1)
            }}
          >
              {currentPage}
          </S.PaginationItem>
        )}

        <S.PaginationItemActive
          onClick={() => {
            setPage(currentPage)
          }}
        >
            {currentPage + 1}
        </S.PaginationItemActive>

        {(currentPage + 2) < totalPages && (
          <S.PaginationItem
            onClick={() => {
              setPage(currentPage + 1)
            }}
          >
            {currentPage + 2}
          </S.PaginationItem>    
        )}

        {(currentPage + 1) < totalPages && (
          <S.PaginationItem
            onClick={() => {
              setPage(totalPages - 1)
            }}
          >
              {totalPages}
          </S.PaginationItem>
        )}
      </S.PaginationListItem>
      <S.PaginationListItem>
        <S.PaginationListItemLink to={frontLink()} disabled={isNotAbleToNext}>
          <S.PaginationIconRight 
            onClick={() => {
              setPage(currentPage + 1)
            }} />
        </S.PaginationListItemLink>
      </S.PaginationListItem>
    </S.PaginationList>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
}

export default Pagination
