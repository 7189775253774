import React from 'react'
import PropTypes from 'prop-types'

import * as Acc from 'react-accessible-accordion'
import * as S from './styled'

const Accordion = props => {
  const openUids = []

  props.items.forEach(item => {
    if (item.openDesktop) {
      openUids.push(item.uuid)
    }
  })

  return (
    <S.Accordion
      data-cy="accordion"
      preExpanded={openUids}
      >
      {props.items.map(item => (
        <S.AccordionItem key={item.title} uuid={item.uuid}>
          <Acc.AccordionItemHeading>
            <S.AccordionItemButton>
              <S.AccordionItemButtonTitle>
                {item.title}
              </S.AccordionItemButtonTitle>
            </S.AccordionItemButton>
          </Acc.AccordionItemHeading>
          <S.AccordionItemPanel>
            {item.content}
          </S.AccordionItemPanel>
        </S.AccordionItem>
      ))}
    </S.Accordion>
  )
}

Accordion.propTypes = {
  items: PropTypes.array.isRequired,
}

export default Accordion
