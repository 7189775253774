import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { ChevronRight } from '@dasa-health/alma-icons'
import * as S from './styled'
import Img from 'gatsby-image'
import Pagination from './Pagination'
import formatDoctorNameTwoLines from '../../utils/formatDoctorNameTwoLines' 
import arrayPagination from '../../utils/arrayPaginate'
import Sidebar from './Sidebar'
import PinkLine from '../shared/PinkLine'
import DoctorLink from '../../utils/getDoctorLink'

const ClinicalStaff = ({ pageData, doctorData, slug }) => {
  const MAX_CARDS_VISIBLE = 16

  const [filterOptions, setFilterOptions] = useState([])

  const [doctorsList, setDoctorsList] = useState(arrayPagination(doctorData, MAX_CARDS_VISIBLE))

  const [countPages, setCountPages] = useState(doctorsList.length)

  const [page, setPage] = useState(0)

  const [firstRender, setFirstRender] = useState(1)

  function alphabeticalOrder(array) {
    return array.sort(function(a,b) {
      if(a.text < b.text) {
        return -1
      } else {
        return true
      }
    })
  }

  const removeRepeatedOptions = data => {
    const removing = Array.from(
      new Set(
        data.map(doctor => {
          return doctor.acf.specialization_area
        })
      )
    )

    const newAreas = removing.map((spec, index) => ({
      id: index,
      text: spec,
      count: matchingFilteredCard(spec).length
    }))

    return alphabeticalOrder(newAreas)
  }

  function matchingFilteredCard(selectedOption) {
    return selectedOption
    ? doctorData.filter(item => item.acf.specialization_area === selectedOption)
    : doctorData
  }

  function handleAreaSelection(area) {
    const filteredsAfterSelection = matchingFilteredCard(area)
    setPage(0)
    setDoctorsList(arrayPagination(filteredsAfterSelection, MAX_CARDS_VISIBLE))
  }

  const [filter, setFilter] = useState([])

  useEffect(() => {
    if(typeof window !== `undefined`) {
      window.scrollTo({
        top: 0,
        left: 100,
        behavior: 'smooth'
      });
    }
  }, [page])

  useEffect(() => {
    setFilterOptions(removeRepeatedOptions(doctorData))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCountPages(doctorsList.length)
  }, [doctorsList])

  useEffect(() => {
    if(firstRender) return setFirstRender(0)

    handleAreaSelection(filter)
  }, [filter]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <S.ClinicalStaffContainer>
        <S.PageTitle>{pageData.subtitle}</S.PageTitle>
        <S.PageDescription>{pageData.demonstrative_text}</S.PageDescription>
        <S.ClinicalStaffContent>
          <S.AreasSidebarHolder>
            <Sidebar 
              items={filterOptions}
              filter={filter}
              setFilter={setFilter}
              data={pageData}
            />
          </S.AreasSidebarHolder>

          <S.CardContainer>
            {doctorsList[page].map((doctor) => (
              <S.CardClinicalStaffItem>
                <S.ImgClinicalStaff>
                  <Img fluid={doctor.acf.photo_medic.localFile.childImageSharp.fluid} />
                </S.ImgClinicalStaff>

                <S.Name>
                  <Link to={`${DoctorLink({ title: doctor.acf.title_gender, name: doctor.slug })}`}>
                    <div dangerouslySetInnerHTML={{ __html: formatDoctorNameTwoLines(doctor.acf.title_gender, doctor.acf.name) }} />
                  </Link>

                  <S.Specialization>
                    {doctor.acf.specialization_area}
                  </S.Specialization>
                </S.Name>

                <S.LinkDoctor>
                  <Link to={`${DoctorLink({ title: doctor.acf.title_gender, name: doctor.slug })}`}>
                    <ChevronRight />
                  </Link>
                </S.LinkDoctor>
              </S.CardClinicalStaffItem>
              ))}
          </S.CardContainer>
        </S.ClinicalStaffContent>
        <Pagination 
            currentPage={page}
            totalPages={countPages}
            setPage={setPage}
            slug={slug}
        />
      </S.ClinicalStaffContainer>
      <PinkLine />
    </>
  )
}

ClinicalStaff.propTypes = {
  doctorData: PropTypes.object,
}

export default ClinicalStaff
