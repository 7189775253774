import styled from 'styled-components'
import { alvTokens } from '@dasa-health/alma-tokens'

export const SidebarListItemHolder = styled.ul`
  list-style: none;
  margin: 1.4rem 0 0;
  padding: 0;

  & > * {
    margin-bottom: 1.4rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const SidebarListItem = styled.li`
  font-size: 14px;
  color: #7D7D7D;
`

export const CheckboxHolder = styled.label`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

export const CheckboxHolderDivider = styled.span`
  font-size: 14px;
  color: ${alvTokens.ColorPaletteWhite};
  font-weight: 400;
  line-height: 170%;
`

export const SpecialtyText = styled.span`
  font-size: 16px;
  color: ${alvTokens.ColorPaletteWhite};
  font-weight: 400;
  line-height: 150%;
  margin-left: 1.8rem;
  letter-spacing: 0;
`
