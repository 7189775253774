import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo'
import ClinicalStaff from 'components/clinical-staff'

const ClinicalStaffPage = ({ data, location, pageContext }) => {
  const wordpressData = data.allWordpressWpClinicalStaffPage.nodes
  const wordpressPage = data.wordpressWpGatsbyPage.acf

  return (
    <Layout location={location} pageContext={pageContext}>
      <SEO
        title={wordpressPage.title}
        description={wordpressPage.subtitle}
        canonical={wordpressPage.canonical}
        pathName={location.pathname}
      />

      <ClinicalStaff 
        pageData={wordpressPage}
        doctorData={wordpressData}
        slug={pageContext.slug}
      />
    </Layout>
  )
}

export default ClinicalStaffPage

ClinicalStaffPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query($slug: String!) {
    wordpressWpGatsbyPage(slug: { eq: $slug }) {
      acf {
        title
        subtitle
        canonical
        demonstrative_text
        filtro_area_atuacao
      }
    }
    allWordpressWpClinicalStaffPage(sort: { fields: acf___name, order: ASC }) {
      nodes {
        acf {
          council_number
          title_gender
          name
          description
          biography
          specialization_area
          photo_medic {
            alt_text
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        slug
      }
    }
  }
`
