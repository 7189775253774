import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from '../../shared/Checkbox'

import * as S from './styled'

const RefinementList = (props) => (
  <S.SidebarListItemHolder>
    {props.items.map(item => (
      <S.SidebarListItem key={item.text}>
        <S.CheckboxHolder data-testid="sidebar-item">
          <S.CheckboxHolderDivider>
            <Checkbox 
              checked={item.text === props.filter ? "checked" : ""}
              onClick={() => {
                props.setFilter(item.text !== props.filter ? item.text : "")
              }}
            />
            <S.SpecialtyText data-testid="sidebar-label">
              {props.labelTransformer(item.text)}
            </S.SpecialtyText>
          </S.CheckboxHolderDivider>
          <S.CheckboxHolderDivider>{item.count}</S.CheckboxHolderDivider>
        </S.CheckboxHolder>
      </S.SidebarListItem>
    ))}
  </S.SidebarListItemHolder>
)

RefinementList.propTypes = {
  refine: PropTypes.func,
  items: PropTypes.array,
  labelTransformer: PropTypes.func,
}

RefinementList.defaultProps = {
  labelTransformer: label => label,
}

export default RefinementList
