import styled from 'styled-components'
import media from 'styled-media-query'
import { ContainerDasa } from '../shared/container'
import { 
  ClinicalStaffImgDiv, 
  ClinicalStaffItem,
  ClinicalStaffName,
  ClinicalStaffSpecialization,
  Link
} from './HomeWidget/styled'
import { ChevronLeft, ChevronRight } from '@dasa-health/alma-icons'
import { alvTokens } from '@dasa-health/alma-tokens'

export const ClinicalStaffContainer = styled(ContainerDasa)`
  margin: 2.4rem auto 5.6rem;

  ${media.lessThan('medium')`
    margin-bottom: 3.2rem;
  `}
`

export const PageTitle = styled.h1`
  font-size: 40px;
  color: ${props => props.theme.colors.brand.primary[10]};
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -1px;
  margin: 0 0 2.4rem;

  ${media.lessThan('medium')`
    font-size: 20px;
  `}
`

export const PageDescription = styled.p`
  font-size: 14px;
  color: ${alvTokens.ColorPaletteGray30};
  font-weight: 400;
  line-height: 170%;
  margin: 0 0 5.6rem;
  max-width: 75.9rem;

  ${media.lessThan('medium')`
    margin: 0 0 3.2rem;
    color: ${alvTokens.ColorPaletteGray30};
  `}
`

export const ClinicalStaffContent = styled.div`
  display: flex;
  gap: 2.4rem;
  margin-bottom: 2.4rem;

  ${media.lessThan('medium')`
    width: 100%;
    gap: 1.6rem;
  `}
`

export const CardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 2.4rem;
`

export const CardClinicalStaffItem = styled(ClinicalStaffItem)`
  display: inline-flex;
  max-width: 27.7rem;
  background: var(--neutral00);
  
  ${media.lessThan('medium')`
    justify-content: space-evenly;
    max-width: 100%;
    width: calc(100% - 3.2rem);
    background: ${alvTokens.ColorPaletteWhite};
  `}
`

export const ImgClinicalStaff = styled(ClinicalStaffImgDiv)``

export const Name = styled(ClinicalStaffName)`
  min-width: 17.5rem;
  margin-right: 1.6rem;
  flex-direction: column;

  ${media.lessThan('medium')`
    min-width: 15.125rem;
  `}
`

export const Specialization = styled(ClinicalStaffSpecialization)``
export const LinkDoctor = styled(Link)``

export const AreasSidebarHolder = styled.aside`
  min-width: 26.3rem;
  fill: #e8e8e8 20%;
  padding: 2.4rem;
  height: max-content;
  background: ${props => props.theme.colors.brand.primary[10]};
  border-radius: 4px;

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const PaginationList = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  margin: 6rem 0;
  justify-content: flex-start;
  align-items: center;
  margin: 2.4rem 0 0 33.5rem;
  height: 3.2rem;

  ${media.lessThan('medium')`
    margin: 0;
  `}
`

export const PaginationListItem = styled.li`
  display: inline-block;
  font-size: 12px;
  color: ${props => props.theme.colors.brand.primary[10]};
  letter-spacing: 1px;
`

export const PaginationItem = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.brand.primary[10]};
  padding: .8rem 1.3rem;
  font-weight: 500;
  line-height: 115%;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

export const PaginationItemActive = styled(PaginationItem)`
  background-color: ${props => props.theme.colors.brand.primary[10]};
  color: var(--white);
  border-radius: 4px;
`

export const PaginationListItemLink = styled(Link)`
  width: 3.8rem;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  color: ${props => props.theme.colors.brand.primary[10]};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`

export const PaginationIconLeft = styled(ChevronLeft)`
  font-size: 24px !important;
  color: ${props => props.theme.colors.brand.primary[10]};
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

export const PaginationIconRight = styled(ChevronRight)`
  font-size: 24px !important;
  color: ${props => props.theme.colors.brand.primary[10]};
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`