import React from 'react'
import AccordionNew from '../../shared/AccordionNew'
import CustomRefinementList from './CustomRefinementList'

const Sidebar = (props) => {
  const titleFilter = props.data.filtro_area_atuacao

  const filters = [
    {
      title: titleFilter,
      content: (
        <CustomRefinementList
          items={props.items}
          attribute="text"
          limit={100}
          setFilter={props.setFilter}
          filter={props.filter}
        />
      ),
      openDesktop: true,
      openMobile: false,
    },
  ]
  
  return (
      <AccordionNew items={filters} />
  )
}

export default Sidebar
