import styled from 'styled-components'
import { alvTokens } from '@dasa-health/alma-tokens'
import * as A from 'react-accessible-accordion'

export const AccordionItemButton = styled(A.AccordionItemButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  outline: none;
  position: relative;
`

export const AccordionItemButtonTitle = styled.h2`
  font-size: 12px;
  color: ${alvTokens.ColorPaletteWhite};
  text-transform: ${props => (props.singleExam ? 'none' : 'uppercase')};
  font-weight: 700;
  line-height: 133%;
  letter-spacing: 1.5px;
  margin: 0;
`

export const AccordionItem = styled(A.AccordionItem)`
  display: ${props => (props.hidden ? 'none' : 'block')};
`

export const AccordionItemPanel = styled(A.AccordionItemPanel)`
  display: block;
  margin-top: 0.8rem;
`

export const Accordion = styled(A.Accordion)`
  & > * {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
`
