const formatDoctorNameTwoLines = (title, name) => {
    let string = name.split(" ");
    let formattedName = title + " " + string[0] +  "<br/>"
    
    for (let index = 1; index < string.length; index++) {
      formattedName += string[index]  + " ";
    }

    return formattedName;
}
  
module.exports = formatDoctorNameTwoLines